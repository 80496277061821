/*
  Based on TailwindCSS recommendations,
  consider using classes instead of the `@apply` directive
  @see https://tailwindcss.com/docs/reusing-styles#avoiding-premature-abstraction
*/
/* src/styles/MainContent.css */
.main-content {
  @apply flex bg-gray-900 text-[white] ;
}

.content-area {
  @apply flex-1  m-5 mt-10 p-5 pt-0 overflow-y-auto;
}

.header {
  @apply sticky z-[100] p-5 top-0; /* background-color: #111827; Ensure header blends with content area */ /* Ensure header is above scrolling content */
}

.levels-container {
  @apply flex flex-col items-center ;
}

.pdf-viewer {
  @apply flex justify-center items-center mt-5;
}


.main-content-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.main-content {
  padding: 20px;
  overflow-y: auto;
}

.main-content.hidden {
  display: none;
}

.fixed.inset-0 {
  display: flex;
  /* align-items: flex-start; */
  justify-content: center;
}

.max-w-4xl {
  max-width: 90%;
}

.note-button {
  position: absolute;
  top: 95px; /* Adjust as needed to match the ideal vertical position */
  right: 24px; /* Keep the button closer to the right side */
  z-index: 50;
  display: flex;
  align-items: center;
  color: rgb(208, 35, 35);
}

/* Fire shape styling */
.fire-shape {
  position: relative;
  width: 2rem;
  height: 3rem;
  background-color: #f59e0b; /* Adjust base color */
  clip-path: polygon(50% 0%, 80% 20%, 100% 50%, 75% 100%, 50% 80%, 25% 100%, 0% 50%, 20% 20%);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Animation for highlighted flames */
.flame-animate {
  animation: 1.5s ease-in-out infinite alternate;
}

/* Flicker animation for flame effect */
@keyframes flicker {
  0%, 100% {
      transform: rotate(0deg) scale(1);
      background-color: #f59e0b;
  }
  50% {
      transform: rotate(180deg) scale(1.1);
      background-color: #ffb703;
  }
}
