/*
  Based on TailwindCSS recommendations,
  consider using classes instead of the `@apply` directive
  @see https://tailwindcss.com/docs/reusing-styles#avoiding-premature-abstraction
*/
/* src/styles/LevelItem.css */
.levels-container {
  @apply flex flex-col items-center p-8 space-y-4 rounded-lg shadow-lg ;
  /* width: calc(100% - 200px); */
}

.level-item {
  @apply w-20 h-20 flex items-center justify-center bg-green-400 
  text-black text-sm text-center mx-0 my-2.5 rounded-full transition-colors 
  duration-300 ease-in-out transform hover:scale-105;
  /* width: calc((100% - 1rem) / 4); 
  height: calc((100% - 1rem) / 4);  */
}

.level-item.unlocked {
  @apply bg-green-400 hover:bg-green-500 shadow-md hover:shadow-lg cursor-pointer;
}

.level-item.first-locked{
  @apply bg-orange-400 hover:bg-orange-500 shadow-md hover:shadow-lg cursor-pointer;
}

.level-item.locked {
  @apply bg-gray-700 text-gray-400 cursor-not-allowed;
}

.lock-icon {
  @apply text-2xl text-gray-400;
}


/* styles.css */
@keyframes gradient-bg-purple {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse-grow-purple {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}


.levels-cummulative {
  @apply text-white font-semibold px-6 py-3 rounded-full cursor-pointer shadow-lg 
         transition-transform duration-500 ease-in-out transform hover:scale-110
         focus:outline-none focus:ring-4 focus:ring-purple-500 focus:ring-offset-2;
  
  /* Animated gradient background in purple tones */
  /* background: linear-gradient(45deg, #7f5af0, #9059c9, #a56bdb, #7f5af0); */
  background: linear-gradient(45deg, #6b21a8, #4c4cab, #4c4cab, #6b21a8);

  background-size: 200% 200%;
  animation: gradient-bg-purple 5s ease infinite, pulse-grow-purple 3s infinite;

  /* Extra rounded, pill-like shape */
  border-radius: 50px;
}

@keyframes gradient-bg-gray {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse-grow-gray {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.levels-cummulative-inactive {
  @apply text-gray-400 font-semibold px-6 py-3 rounded-full cursor-not-allowed shadow-md 
         transition-transform duration-500 ease-in-out transform;

  /* Lighter background color to signify inactivity */
  background: linear-gradient(45deg, #443e45, #656565);
  background-size: 200% 200%;
  /* Reduced animation for inactive state */
  animation: gradient-bg-gray 5s ease infinite, pulse-grow-gray 3s infinite;

  /* Extra rounded, pill-like shape */
  border-radius: 50px;
}
