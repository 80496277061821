.custom-navbar {
  @apply fixed top-0 left-0 w-full max-w-full flex items-center py-2 px-5 bg-purple-800 text-white z-50;
}

.custom-navbar-logo {
  @apply flex items-center ml-4 text-white no-underline p-3  min-w-[150px] min-h-[50px] font-extrabold;
  /* @apply border-2 border-purple-900; 
  @apply bg-purple-900 bg-opacity-20; */
}

.custom-navbar-logo-img {
  @apply w-8 h-auto mr-2;
}

.custom-navbar-center {
  @apply flex-grow text-end;
}

.breadcrumb-link {
  @apply cursor-pointer font-bold;
}

.breadcrumb-link:hover {
  @apply underline;
}

.custom-navbar-logo-text {
  @apply font-bold text-xl cursor-pointer;
}

@media screen and (max-width: 768px) {
  .custom-navbar-links.open {
    @apply flex;
  }
}

.streak-display {
  @apply  p-3 rounded-lg  ml-2 min-w-[150px] min-h-[50px] font-extrabold;
  /* @apply border-2 border-purple-900;
  @apply bg-purple-900 bg-opacity-20;  */
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));  */
  background-size: 100% 200%; /* Adjust size for the gloss */
  background-position: 0 0; /* Position of the gloss */
  transition: background-position 0.3s; 
}

.streak-display.active {
  @apply text-amber-400 scale-110 cursor-pointer; /* Active streak styles in orange */
}

.streak-display.inactive {
  @apply text-gray-400; /* Inactive streak styles */
}

.streak-icon {
  @apply mr-1; /* Space between icon and number */
}

.streak-display :hover{
  @apply cursor-pointer
}