/* 
/* src/styles/GoalOption.css 

.goal-option {
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
}

.goal-option input {
    margin-right: 10px;
}

.goal-option.selected {
    background-color: #e0f7fa;
    border-radius: 5px;
    padding: 5px;
}


/* src/styles/GoalPicker.css 


.goal-picker {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 40px auto;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  

}

.goal-picker h3 {
    margin-bottom: 20px;
    font-size: 25px;
}

.goal-picker button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: medium;
}

.goal-picker button:hover {
    background-color: #45a049;
}  
*/


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* Global scrollbar styles */
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  
    ::-webkit-scrollbar-track {
      background: #2c2b2b;
      border-radius: 10px;
    }
  
    ::-webkit-scrollbar-thumb {
      background: #ffb524;
      border-radius: 10px;
      transition: background-color 0.3s ease;
    }
  
    ::-webkit-scrollbar-thumb:hover {
        background: #6b1aae;
    }
  
    ::-webkit-scrollbar-corner {
      background: #6d6d6d;
    }
  }