/* Main container - Add border, shadow, and hover effects */
.streak-page-container {
  @apply text-white p-6 rounded-lg shadow-md flex flex-row items-center justify-evenly h-screen text-center ;
}

/* Styling for the two main divs to make them big buttons with borders and hover effects */
.streak-page-container > div {
  @apply w-1/3 h-3/5; /* Each item takes up 50% of the container's width */
  @apply p-4; /* Optional padding to space out the content inside each div */
  @apply rounded-lg border-2 border-transparent transition-all duration-300 ease-in-out cursor-pointer; /* Border effects */
}

/* Hover effect on the two main buttons */
/* .streak-page-container-b:hover{
  @apply bg-purple-900 border-gray-900 font-extrabold scale-105 shadow-lg; 
} */

.streak-page-container-b {
  @apply relative w-64 h-64 flex items-center justify-center text-center text-white font-bold text-xl;
  @apply rounded-lg cursor-pointer select-none transition-all duration-200 ease-out;
  @apply bg-gradient-to-b from-[#2E1A47] to-[#0A1E3D]; /* Cosmic twilight gradient */
  @apply border-b-[10px] border-r-[10px] border-[#008B8B]; /* Teal-blue border for accent */
  @apply shadow-[0_10px_10px_0_rgba(26,26,26,0.8),0_15px_15px_0_rgba(15,15,15,0.7)]; /* Shadow for depth */
  @apply -translate-y-2 -translate-x-2; /* Slight lift for the 3D effect */
}

/* Layer 1 - Cosmic gradient background */
.streak-page-container-b span:nth-child(1) {
  @apply absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-gradient-to-b from-[#2E1A47] to-[#0A1E3D];
  @apply group-hover:-translate-x-0 group-hover:-translate-y-0; /* Move back on hover */
}

/* Layer 2 - Border and hover effect with cosmic colors */
.streak-page-container-b span:nth-child(2) {
  @apply absolute inset-0 w-full h-full border-2 border-[#008B8B];
  @apply group-hover:bg-[#4B3F72]; /* Background turns to a deep purple on hover */
}

/* Layer 3 - Text color with cosmic teal */
.streak-page-container-b span:nth-child(3) {
  @apply relative text-[#008B8B] group-hover:text-white; /* Text turns white on hover */
}

/* Hover effect */
.streak-page-container-b:hover {
  @apply -translate-y-1 -translate-x-1; /* Slight reduction in lift on hover */
  @apply bg-gradient-to-b from-[#4B3F72] to-[#2E1A47]; /* Reverse gradient for hover effect */
  @apply shadow-[0_8px_8px_0_rgba(26,26,26,0.8),0_12px_12px_0_rgba(15,15,15,0.7)]; /* Hover shadow effect */
}

/* Active effect for button press */
.streak-page-container-b:active {
  @apply translate-y-0 translate-x-0; /* Button sinks completely when pressed */
  @apply bg-gradient-to-b from-[#D1A0D4] to-[#2E1A47]; /* Light magenta to purple gradient for active state */
  @apply shadow-[0_4px_4px_0_rgba(26,26,26,0.8),0_6px_6px_0_rgba(15,15,15,0.7)]; /* Reduced shadow on press */
  @apply border-b-0 border-r-0; /* Remove borders on active press */
}



/* Apply bg-purple-600 when no progress is available */
.streak-page-container.no-progress > div {
  @apply bg-purple-900; /* Conditional purple background when no progress */
}

/* Scrollable wrapper for subtopics */
.streak-page-subtopics-wrapper {
  @apply overflow-y-scroll rounded-lg shadow-lg max-h-[600px] bg-gray-800 border border-gray-600;
}

/* Scrollable container for subtopic list */
.streak-page-subtopics-container {
  @apply overflow-y-auto;
  max-height: 100%; /* To ensure it doesn't exceed the wrapper's height */
}

/* Styling for subtopic options with interactive hover and selected styles */
.subtopic-option {
  @apply p-4  mt-1 cursor-pointer transition-all duration-200 ease-in-out text-center w-full rounded-md;
  @apply border border-transparent; /* Subtle border change on hover */
}

/* Selected subtopic option style */
.subtopic-option.selected {
  @apply bg-green-500 text-black border-gray-600; /* Highlighted when selected */
}

/* Subtopic option hover effect */
.subtopic-option:hover {
  @apply  rounded bg-gray-700  cursor-pointer border-gray-400; 
}

/* List container for subtopics */
.streak-page-list-container {
  @apply h-96 w-full overflow-y-scroll border rounded-md p-4 shadow-sm border-gray-600;
}

/* Subtopic option styling */
.streak-page-subtopic-option {
  @apply mb-4 p-2 rounded-lg shadow-sm border border-gray-700;
}

/* Subtopic option selected style */
.streak-page-subtopic-option.selected {
  @apply bg-green-500 text-black;
}

/* Generate button styling with hover effects */
.streak-page-generate-button {
  @apply mt-4 px-6 py-2 bg-[#5c4b8a] rounded-lg hover:bg-yellow-600 cursor-pointer transition-all duration-200 text-white font-bold ease-in-out;
  @apply border-2 border-transparent hover:border-blue-600; /* Border effect on hover */
}

/* Hover effects for buttons */
.streak-page-generate-button:hover {
  @apply scale-105 shadow-lg text-black; /* Slight scale and shadow effect on hover */
}

@media (max-width: 768px) {
  .streak-page-container {
    @apply flex-col ;
  }
  .streak-page-container > div {
    @apply w-2/3 h-2/3 mb-5; /* Each item takes up 50% of the container's width */
    @apply p-4; /* Optional padding to space out the content inside each div */
    @apply rounded-lg border-2 border-transparent transition-all duration-300 ease-in-out cursor-pointer; /* Border effects */
  }
}