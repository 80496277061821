.sidebar {
  /* @apply w-[200px] bg-gray-900 h-screen fixed flex flex-col items-center 
  pt-5 right-0 rounded-lg shadow-lg
   border-gray-900;   */
   @apply w-[200px] bg-gray-900 fixed flex flex-col items-center 
   pt-3 top-12 right-0 rounded-lg shadow-lg
    border-gray-900;
  height: calc(100vh - 3rem);
  overflow-y: auto;
  scrollbar-width: thin; 
  scrollbar-color: #555 #333; 
}

.sidebar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.sidebar::-webkit-scrollbar-track {
  background: #333; /* Background of the scrollbar track */
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  border: 2px solid #333; /* Optional: add border around the thumb */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #888; /* Color on hover */
}

.sidebar-title {
  @apply text-white text-2xl font-bold mb-4 mt-4; 
}

.sidebar-item {
  @apply text-white cursor-pointer px-5 py-[15px] hover:bg-gray-700 rounded-lg shadow-lg w-full
  border-2 border-gray-500; 
}
