.notes-container {
  @apply flex flex-col p-20 bg-gray-900 ;
}

.close-button {
  @apply fixed top-12 right-3 bg-transparent border-none text-xl text-white cursor-pointer z-10;
}

.close-button:hover {
  @apply text-red-600;
}

.notes h1 {
  @apply text-white text-3xl font-bold mb-4;
}

.notes h2 {
  @apply text-white text-2xl font-semibold mt-6 mb-3 border-b-2 border-gray-600 pb-2;
}

.notes h3 {
  @apply text-white text-xl font-semibold mt-4 mb-2;
}

.notes p {
  @apply text-lg leading-relaxed mb-4;
}


.notes ul,
.notes ol {
  @apply list-inside pl-4 mb-4;
}

.notes ul li,
.notes ol li {
  @apply text-lg mb-2;
}


.notes ul {
  @apply list-disc;
}

.notes ol {
  @apply list-decimal;
}

.notes ol li {
  @apply list-decimal pl-6;
}

.notes ul li {
  @apply list-disc pl-6;
}

.notes pre {
  @apply bg-gray-700 text-green-400 p-4 rounded-md overflow-x-auto;
}


.notes blockquote {
  @apply border-l-4 border-gray-600 pl-4 italic text-gray-300 my-4;
}


.notes table {
  @apply w-full border-collapse my-4;
}

.notes th,
.notes td {
  @apply border border-gray-600 p-2 text-left;
}

.notes th {
  @apply bg-gray-700 text-white;
}

.notes td {
  @apply bg-gray-800 text-white;
}

.notes tr:nth-child(even) {
  @apply bg-gray-700;
}

.notes div {
  @apply my-5;
}

.notes img {
  @apply mt-4 ml-4 mb-6; 
}

.notes-content {
  position: fixed;
  top: 1rem;
  left: 1rem;
  bottom: 1rem;
  right: 1rem;
  width: 100%;
  height: 100%;
  background-color: #1b202a; /* Dark background color */
  color: #ffffff; /* White text for readability */
  overflow-y: auto; /* Only vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding: 20px !important; /* Adds padding for comfortable reading */
  box-sizing: border-box; /* Ensure padding is inside the width and height */
}

.notes-content h2, .notes-content p, .notes-content ul, .notes-content li {
  margin-bottom: 1rem; /* Consistent spacing between elements */
  line-height: 1.6; /* Improves readability */
}

.notes-content ul {
  list-style-type: disc; /* Standard list bullet */
  padding-left: 20px; /* Padding for bullets */
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
  z-index: 100; /* Ensure close button is above all other content */
  color: #ffffff; /* Matches the text color */
  border: none;
  background: none;
}

.close-button:hover {
  color: red;
}
